import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import TypeToBlock from "../components/type-to-block";

export const query = graphql`
  query ($languageISO: String!, $language: String!, $slug: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    stripePlanMonthly: stripePlans(interval: { eq: "month" }) {
      id
      currency
      amount
      interval
      interval_count
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
    page: contentfulPages(
      slug: { eq: $slug }
      node_locale: { eq: $languageISO }
    ) {
      slug
      id
      metaDescription
      metaTitle
      modules {
        id
        heading
        name
        subheading
        type
        flipBlocks
        items {
          ... on ContentfulItems {
            content {
              raw
            }
            body
            heading
            id
            link
            linkHelpText
            linkText
            subheading
            desktopImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            mobileImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImage {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            backgroundImageMobile {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
            video
          }
        }
      }
    }
  }
`;

// Page.defaultProps = {
//   metaTitle : "Fitplan", 
//   metaDescription : "Fitplan"
// }

const Page = ({
  data: {
    page: { metaTitle, metaDescription = "Fitplan", modules },
    stripePlanMonthly,
    ribbon,
    header,
    footer,
  },
}) => {
  let hasBanner;
  if(modules[0].type !== "Full page horizontal carousel") {
    hasBanner="no-banner";
  }
  return (
    <>
      <Layout header={header} ribbon={ribbon} footer={footer} hasBanner={hasBanner}>
        <Seo title={metaTitle} metaDescription={metaDescription} />
        {(modules && modules.length) > 0 &&
          modules.map(
            (module, key) =>
              module.type !== "Plan List Block" && (
                <TypeToBlock
                  key={`module-${key}`}
                  stripePlanMonthly={stripePlanMonthly}
                  module={module}
                />
              )
          )}
      </Layout>
    </>
  );
};

export default Page;
